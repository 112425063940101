import React, {useEffect} from "react";
import { Link } from 'react-router-dom';
import Slider from "../Component/Slider";
import aboutContent from '../Content/about.json';
import servicesContent from '../Content/services.json';
import whyChooseContent from '../Content/whyChoose.json';

const Home = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return(
        <React.Fragment>
            <Slider />
            <section className="aboutUs mb-5">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-5">
                            <div className="aboutUsThumb">
                                <img src="images/about-thumb.png" alt=""/>
                            </div>
                        </div>
                        <div className="col-12 col-md-7 ps-5 pr-5">
                            <div className="aboutUsContent">
                                {
                                    aboutContent.about.map((data, index)=>{
                                        return(
                                            <React.Fragment key={index}>
                                                <div className="sectionHeading mb-3">
                                                    <h3>{data.topTitle}</h3>
                                                    <h2>{data.title}</h2>
                                                </div>
                                                <p className="para">{data.body}</p>
                                                <Link to="/about-us" className="colorBtn">Learn More</Link>
                                            </React.Fragment>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="explore" id="servicesSec">
                <div className="container">
                    <div className="row align-items-center mb-3">
                        <div className="col-md-6 col-12">
                            <div className="sectionHeading">
                                <h3>Explore</h3>
                                <h2>Our Services</h2>
                            </div>
                        </div>
                        <div className="col-md-6 col-12">
                            <p className="m-0 para">{servicesContent.paragraph[0].body}</p>
                        </div>
                    </div>
                    <div className="row mt-5">
                        {
                            servicesContent.services.map((data, index)=>{
                                return(
                                    <div className="col-lg-3 col-md-6 col-12" key={index}>
                                        <div className="colorBx">
                                            <React.Fragment >
                                                <img src={data.imageUrl} alt="" />
                                                <h3>{data.title}</h3>
                                                <p>{data.body}</p>
                                                <div className="text-end">
                                                    <Link to="/services" className="colorBtn">Explore </Link>
                                                </div>
                                            </React.Fragment>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </section>
            <section className="whyUs">
                <div className="container">
                    {/* <div className="sectionHeading">
                        <h3>Why</h3>
                        <h2>Choose Us</h2>
                    </div> */}
                    <div className="row align-items-center">
                        <div className="col-md-5 col-12">
                            <img src="images/why-us.png" alt="" className="whyUsThumb"/>
                        </div>
                        <div className="col-md-7 col-12 ps-5">
                            <div className="row">
                                {
                                    whyChooseContent.map((data, index)=>{
                                        return(
                                            <div className="col-md-6 col-12 mb-4" key={index}>
                                                <div className="whyUsBx">
                                                    <div className="text-end">
                                                        <img src={data.imageUrl} alt=""/>
                                                    </div>
                                                    <h3>{data.title}</h3>
                                                    <p>{data.body}</p>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="weWork">
                <div className="container">
                    <div className="row align-items-center mb-3">
                        <div className="col-md-6 col-12">
                            <div className="sectionHeading">
                                <h3>Technologies</h3>
                                <h2>We work with</h2>
                            </div>
                        </div>
                        <div className="col-md-6 col-12">
                            <p className="m-0 para">At Virtual Assistant 24x7, we leverage advanced technologies and platforms to deliver cutting-edge solutions for businesses. Our expertise spans a wide range of industry-leading tools and frameworks, including:</p>
                        </div>
                    </div>
                    <div className="clientList mt-5">
                        <div className="client"><img src="images/sap.png" alt="" /></div>
                        <div className="client"><img src="images/ui-path.png" alt="" /></div>
                        <div className="client"><img src="images/open-ai.png" alt="" /></div>
                        <div className="client"><img src="images/office-365.png" alt="" /></div>
                        <div className="client"><img src="images/mern.png" alt="" /></div>
                        <div className="client"><img src="images/power-bi.png" alt="" /></div>
                        <div className="client"><img src="images/python.png" alt="" /></div>
                        <div className="client"><img src="images/oracle.png" alt="" /></div>
                        <div className="client"><img src="images/aws.png" alt="" /></div>
                        <div className="client"><img src="images/azure.png" alt="" /></div>
                    </div>
                </div>
            </section>
         
        </React.Fragment>
    )
}
export default Home;